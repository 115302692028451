/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns';
import * as helper from './CoreHelpers';

const API_URL = `${process.env.APP_API_URL}/api/v1`;
const globalLocation = window.location;

async function fetchThis(route, data, token = undefined) {
  const currToken = token || helper.getCookie();
  const fetcher = await fetch(`${API_URL}/${route}/${currToken}/desktop`, {
    method: 'POST',
    body: data
  })
    .then(async res => {
      const resData = await res.json();
      return {
        code: res.status,
        ...resData
      };
    })
    .then(resData => {
      // Do this check only after login.
      if (globalLocation.pathname !== '/login') {
        const hasMessage = Object.prototype.hasOwnProperty.call(resData, 'msg');

        // If there is a token error, such as invalid and expired tokens, or a user lost/has not access, remove browser coookie and redirect to login.
        if (
          hasMessage &&
          resData.msg.search(
            /Access denied|User not found|expired_token|invalid_token|user_not_found/
          ) > -1
        ) {
          helper.removeCookie();
          helper.setErrorCookie(resData.msg);
          // Reload browser to clear states.

          globalLocation.reload();
        }
      }

      return resData;
    })
    .catch(err => {
      console.log(`Error Reading data ${err}`);
    });

  return fetcher;
}

export function getUserApi(email, token = undefined) {
  const formData = new FormData();
  formData.append('email', email);
  return fetchThis('rto_get_user_info', formData, token);
}

export function loginUserApi({ email, token }) {
  return getUserApi(email, token);
}

export function impersonateUserApi(email) {
  return getUserApi(email);
}

export function getAwaitingLeavesApi(email) {
  const formData = new FormData();
  formData.append('email', email);
  return fetchThis('rto_awaiting_requests', formData);
}

export function getSubordinatesApi(
  email,
  tab,
  page_index = 1,
  page_sizes = 20,
  with_pagination = 1,
  sort_direction = 'ASC',
  sort_key = 'fullName',
  filters = {
    year: new Date().getFullYear(),
    from_date: `${new Date().getFullYear().toString()}-01-01`,
    to_date: `${new Date().getFullYear().toString()}-12-31`,
    division: 'all division',
    department: 'all department',
    status: 'active',
    search: null
  }
) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('tab', tab);
  formData.append('division', filters.division);
  formData.append('department', filters.department);
  formData.append('status', filters.status);
  formData.append('page_index', page_index);
  formData.append('page_sizes', page_sizes);
  formData.append('with_pagination', with_pagination);
  formData.append('sort_direction', sort_direction);
  formData.append('sort_key', sort_key);

  if (tab === 'hr') {
    formData.append('from_date', filters.from_date);
    formData.append('to_date', filters.to_date);
  } else {
    formData.append('year', filters.year);
  }

  if (filters.search) {
    formData.append('search', filters.search);
  }

  return fetchThis('rto_get_subordinates', formData);
}

export function getLeavesRequestApi(email, year = '') {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('year', year);
  return fetchThis('rto_user_requests', formData);
}

export function getLeavesSummaryApi(email) {
  const formData = new FormData();
  formData.append('email', email);
  return fetchThis('rto_get_user_summary_leaves', formData);
}

export function getRequestInfoApi(requestId) {
  const formData = new FormData();
  formData.append('request_id', requestId);
  return fetchThis('rto_get_request_details', formData);
}

// API Call to Get User's Grail Status
export function getGrailStatusApi(email) {
  const formData = new FormData();
  formData.append('email', email);
  return fetchThis('get_user_grail_status', formData);
}

export function getUsersList() {
  const formData = new FormData();
  return fetchThis('rto_get_all_user_emails', formData);
}

export function getExcludedDatesApi(email) {
  const formData = new FormData();
  formData.append('email', email);
  return fetchThis('rto_get_excluded_dates', formData);
}

export async function postNewRequestApi(data, postType) {
  const formData = new FormData();
  formData.append('requestType', data.requestType);
  formData.append('requestorEmail', data.requestorEmail);
  formData.append('requestorJson', data.requestorJson);
  formData.append('datesJson', JSON.parse(data.datesJson).join(','));
  formData.append('fromDate', format(data.fromDate, 'M/D/YYYY'));
  formData.append('toDate', format(data.toDate, 'M/D/YYYY'));
  formData.append('halfDayPeriod', data.halfDayPeriod);
  formData.append('workDays', data.workDays);
  formData.append('reason', data.reason);
  formData.append('relevantTeamMembersEmails', data.relevantTeamMembersEmails);
  formData.append('requestorComments', data.requestorComments);

  if (postType !== 'default') {
    formData.append('override', 1);
    if (postType === 'override_WithNotif') formData.append('withNotif', 1);
    if (postType === 'override_NoNotif') formData.append('withNotif', 0);
  }

  return fetchThis('rto_add', formData);
}

// API Call to update the request
export function updateRequestStatusApi(
  email,
  requestId,
  status,
  commentForRequestor = '',
  commentForDepartment = ''
) {
  const requestFormData = new FormData();
  requestFormData.append('email', email);
  requestFormData.append('request_id', requestId);
  requestFormData.append('status', status);
  requestFormData.append('comment_for_requestor', commentForRequestor); // Public comment.
  requestFormData.append('comment_for_department', commentForDepartment); // Approver comment
  return fetchThis('rto_update', requestFormData);
}

export function updateRequestHistoryApi(email, requestId, status) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('request_id', requestId);
  formData.append('action', status);
  return fetchThis('rto_save_history_request', formData);
}

export function getRTORawDataCSVApi(
  sort_direction = 'ASC',
  sort_key = 'fullName',
  filters = {
    division: 'all division',
    department: 'all department',
    status: 'active',
    from_date: `${new Date().getFullYear().toString()}-01-01`,
    to_date: `${new Date().getFullYear().toString()}-12-31`,
    search: null
  }
) {
  const formData = new FormData();
  formData.append('sort_direction', sort_direction);
  formData.append('sort_key', sort_key);
  formData.append('division', filters.division);
  formData.append('department', filters.department);
  formData.append('status', filters.status);
  formData.append('from_date', filters.from_date);
  formData.append('to_date', filters.to_date);

  if (filters.search) {
    formData.append('search', filters.search);
  }

  return fetchThis('rto_export_leave_details', formData);
}
